<template>
  <div class="workticket-disclosure-sign">
    <van-nav-bar :title="title" fixed left-arrow @click-left="backClick()">
    </van-nav-bar>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
